<template>
  <div>
    <v-row align="start" justify="center">
      <v-col cols="12" lg="8">
        <v-toolbar tile flat color="transparent">
          <v-toolbar-title>
            Audio Codecs
          </v-toolbar-title>
          <v-spacer/>
          <v-btn fab small color="success" elevation="0" @click="Save"><v-icon>mdi-content-save</v-icon></v-btn>
        </v-toolbar>
        <!-- Main Codec Order -->
        <v-card outlined class="px-3 py-2 mb-3">
          <h3 id="codecOrder" class="title">Main Codec Order</h3>
          <h4 class="font-weight-light mb-3">Drag and drop the codec order</h4>
          <draggable
              v-model="codecOrder"
              group="codecOrder"
              @start="drag=true"
              @end="drag=false"
          >
            <v-card
                class="mb-3 pb-0"
                v-for="(codec, index) in codecOrder"
                :key="index"
                style="cursor: pointer"
                :outlined="!codec.enabled"
                :dark="codec.enabled"
                :color="codec.enabled ? 'primary lighten-1':''"
            >
              <v-list-item>
                <v-list-item-avatar>
                  <v-checkbox v-model="codec.enabled" color="dark-blue"/>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{codec.name}}</v-list-item-title>
                  <v-list-item-subtitle>{{codec.codec}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </draggable>
        </v-card>
        <!-- Codec Order 3g -->
        <v-card outlined class="px-3 py-2 mb-3">
          <h3 id="codecOrder3G" class="title">Codec Order 3G</h3>
          <h4 class="font-weight-light mb-3">Drag and drop the codec order</h4>
          <draggable
              v-model="codecOrder3G"
              group="codecOrder"
              @start="drag=true"
              @end="drag=false"
          >
            <v-card
                class="mb-3 pb-0"
                v-for="(codec, index) in codecOrder3G"
                :key="index"
                style="cursor: pointer"
                :color="codec.enabled ? 'primary lighten-1':''"
                :dark="codec.enabled"
            >
              <v-list-item>
                <v-list-item-avatar>
                  <v-checkbox v-model="codec.enabled" color="dark-blue"/>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{codec.name}}</v-list-item-title>
                  <v-list-item-subtitle>{{codec.codec}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </draggable>
        </v-card>
        <!-- Codec Order WebRTC -->
        <v-card outlined class="px-3 py-2 mb-3">
          <h3 id="codecOrderWebrtc" class="title">Codec Order WebRTC</h3>
          <h4 class="font-weight-light mb-3">Drag and drop the codec order</h4>
          <draggable
              v-model="codecOrderWebrtc"
              group="codecOrder"
              @start="drag=true"
              @end="drag=false"
          >
            <v-card
                class="mb-3 pb-0"
                v-for="(codec, index) in codecOrderWebrtc"
                :key="index"
                style="cursor: pointer"
                :color="codec.enabled ? 'primary lighten-1':''"
                :dark="codec.enabled"
            >
              <v-list-item>
                <v-list-item-avatar>
                  <v-checkbox v-model="codec.enabled" color="dark-blue"/>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{codec.name}}</v-list-item-title>
                  <v-list-item-subtitle>{{codec.codec}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </draggable>
        </v-card>
        <!-- OPTIONS -->
        <div
            v-for="(option, index) of options"
            :key="index"
        >
          <v-card outlined class="pa-3 mb-4">
            <h4 :id="option.option" class="text-capitalize font-weight-light pl-3 mb-1">{{option.option}}</h4>
            <v-text-field
                :placeholder="option.option"
                filled
                rounded
                dense
                hide-details
                class="mb-2"
                v-model="config[option.option]"
            />
            <div class="pl-3">
              <div class="font-weight-light">{{option.hint}}</div>
              <div><span class="blue-grey--text">Default:</span> {{option.default}}</div>
              <div><span class="blue-grey--text">Example:</span> {{option.example}}</div>
              <div v-if="option.validValues">
                <span class="blue-grey--text">Vaild Values:</span>
                <v-chip
                    v-for="value of option.validValues"
                    :key="value"
                    color="primary"
                    class="mx-1"
                    small
                >{{value}}</v-chip>
              </div>
            </div>
          </v-card>
        </div>
      </v-col>
      <v-col cols="4">
        <v-card outlined
                :style="currentScroll > 10 ? 'position: fixed; top: 260px; width: 260px':''"
                class="hidden-md-and-down"
        >
          <v-card-title>Options</v-card-title>
          <v-card-text>
            <!-- Main Codec Order -->
            <h3 class="body-1" style="cursor: pointer" @click="$vuetify.goTo('#codecOrder')">
              Main Codec Order
            </h3>
            <!-- 3G Codec Order -->
            <h3 class="body-1" style="cursor: pointer" @click="$vuetify.goTo('#codecOrder3G')">
              3G Codec Order
            </h3>
            <!-- WebRTC codec order -->
            <h3 class="body-1" style="cursor: pointer" @click="$vuetify.goTo('#codecOrderWebrtc')">
              WebRTC Codec Order
            </h3>
            <!-- Auto gen goto's -->
            <v-divider class="my-2"/>
            <h3
                v-for="(item, index) of options"
                :key="index"
                @click="$vuetify.goTo(`#${item.option}`)"
                class="body-1"
                style="cursor: pointer"
            >{{index = index + 1}}: {{item.option}}</h3>
            <v-btn
                v-if="currentScroll > 10"
                color="success"
                style="margin-right: 60px"
                @click="Save"
                block
                class="my-2"
            >
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
            <v-btn
                v-if="currentScroll > 10"
                color="primary"
                @click="$vuetify.goTo(0)"
                block
            >
              <v-icon>mdi-arrow-up-bold</v-icon>
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  name: "audioCodecs",
  components: {draggable},
  props: {
    domain:{
      type: String,
      required: true,
    }
  },
  data() {
    return{
      codecOrder:[
        {codec: '103', name: 'Opus', enabled: false},
        {codec: '9', name: 'G722 wideband codec', enabled: false},
        {codec: '0', name: 'G711 μ-law', enabled: false},
        {codec: '8', name: 'G711 a-law', enabled: false},
        {codec: '18', name: 'G729', enabled: false},
        {codec: '102', name: 'iLBC', enabled: false},
        {codec: '3', name: 'GSM', enabled: false},
      ],
      codecOrder3G: [
        {codec: '18', name: 'G729', enabled: false},
        {codec: '103', name: 'Opus', enabled: false},
        {codec: '102', name: 'iLBC', enabled: false},
        {codec: '3', name: 'GSM', enabled: false},
        {codec: '9', name: 'G722 wideband codec', enabled: false},
        {codec: '0', name: 'G711 μ-law', enabled: false},
        {codec: '8', name: 'G711 a-law', enabled: false},
      ],
      codecOrderWebrtc: [
        {codec: '103', name: 'Opus', enabled: false},
        {codec: '9', name: 'G722 wideband codec', enabled: false},
        {codec: '0', name: 'G711 μ-law', enabled: false},
        {codec: '8', name: 'G711 a-law', enabled: false},
      ],
      config: {},
      currentScroll: 0,
      options:[
        {
          option: 'honorTheirCodecListWiFi',
          hint: 'Forces the application to use the first codec offered by the remote side for calls over Wifi',
          default: '1',
          example: '1',
          validValues: ['0', '1'],
        },
        {
          option: 'honorTheirCodecList3G',
          hint: 'Forces the application to use the first codec offered by the remote side for calls over 3G',
          default: '0',
          example: '0',
          validValues: ['0', '1'],
        },
        {
          option: 'ptime',
          hint: 'packet time in milliseconds, to be used with WiFi codec set.',
          default: '20',
          example: '20',
          validValues: ['number'],
        },
        {
          option: 'ptime3G',
          hint: 'packet time in milliseconds, to be used with 3G codec set.',
          default: '30',
          example: '30',
          validValues: ['number'],
        },
        {
          option: 'forcePtime',
          hint: 'If set to 1, the ptime parameter will be used even if the remote side requests a different value.',
          default: '0',
          example: '1',
          validValues: ['0', '1'],
        },
        {
          option: 'forcePtime3G',
          hint: 'If set to 1, the ptime3G parameter will be used even if the remote side requests a different value.',
          default: '0',
          example: '1',
          validValues: ['0', '1'],
        },
      ]
    }
  },
  beforeMount() {
    this.$axios.get('acrobits/get-settings', {params:{domain: this.domain}}).then(res=>{
      this.config = Object.assign({}, res.data)

      /*Sort main codec order*/
      let codecOrder = this.config.codecOrder.split(',')
      this.codecOrder.sort((a,b) => codecOrder.indexOf(a.codec) - codecOrder.indexOf(b.codec))
      for (let codec of this.codecOrder) {
        if(codecOrder.find(t => t === codec.codec))
          codec.enabled = true
      }
      this.codecOrder.sort((a, b) => {
        const aIndex = codecOrder.indexOf(a.codec);
        const bIndex = codecOrder.indexOf(b.codec);

        return (aIndex !== -1 ? aIndex : codecOrder.length) - (bIndex !== -1 ? bIndex : codecOrder.length)
      })

      /*Sort 3g codec order*/
      let codecOrder3g = this.config.codecOrder3G.split(',')
      this.codecOrder3G.sort((a, b) =>
          codecOrder3g.indexOf(a.codec) - codecOrder3g.indexOf(b.codec) &&
          codecOrder3g.indexOf(a.codec) + codecOrder3g.indexOf(b.codec)
      )
      for (let codec of this.codecOrder3G) {
        if(codecOrder3g.find(t => t === codec.codec))
          codec.enabled = true
      }
      this.codecOrder3G.sort((a, b) => {
        const aIndex = codecOrder3g.indexOf(a.codec);
        const bIndex = codecOrder3g.indexOf(b.codec);

        return (aIndex !== -1 ? aIndex : codecOrder.length) - (bIndex !== -1 ? bIndex : codecOrder.length)
      })

      /*Sort WebRTC codec order*/
      let codecWebRtc = this.config.codecOrderWebrtc.split(',')
      this.codecOrderWebrtc.sort((a, b) => codecWebRtc.indexOf(a.codec) - codecWebRtc.indexOf(b.codec))
      for (let codec of this.codecOrderWebrtc) {
        if(codecWebRtc.find(t => t === codec.codec))
          codec.enabled = true
      }
      this.codecOrderWebrtc.sort((a, b) => {
        const aIndex = codecWebRtc.indexOf(a.codec);
        const bIndex = codecWebRtc.indexOf(b.codec);

        return (aIndex !== -1 ? aIndex : codecOrder.length) - (bIndex !== -1 ? bIndex : codecOrder.length)
      })

    })
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.updateScroll)
  },
  methods:{
    updateScroll() {
      this.currentScroll = window.scrollY
    },
    Save() {
      /*Update main codec order*/
      this.config.codecOrder = this.codecOrder.filter(codec => codec.enabled)
      this.config.codecOrder = this.config.codecOrder.map(codec=>{
        return codec.codec
      }).join()

      /*updated 3g codec order*/
      this.config.codecOrder3G = this.codecOrder3G.filter(codec => codec.enabled)
      this.config.codecOrder3G = this.config.codecOrder3G.map(codec =>{
        return codec.codec
      }).join()

      /*update webrtc codec order*/
      this.config.codecOrderWebrtc = this.codecOrderWebrtc.filter(codec => codec.enabled)
      this.config.codecOrderWebrtc = this.config.codecOrderWebrtc.map(codec =>{
        return codec.codec
      }).join()

      this.$emit('save-settings', this.config)
    }
  }
}
</script>

<style scoped>

</style>